import {I18nextProvider} from "react-i18next";
import i18n from "../i18n";
import {LocalStorageContext} from "../common/context";
import {NamespacedLocalStorage} from "@blocksure/blocksure-core/dist/src/services";
import {getBase, SureAppName} from "../base";
import {Elements, StripeProvider} from "react-stripe-elements";
import {TradingGroupProvider} from "../tradinggroup/TradingGroup";
import {PendingProvider} from "../common/PendingProvider";
import {AuthenticationProvider} from "../auth/AuthenticationProvider";
import {AuthenticatedRoutes} from "../auth/AuthenticatedRoutes";
import Footer, {PoweredBy} from "../widgets/Footer";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import React, {useEffect, useLayoutEffect} from "react";
import {valueOrEnvVariable} from "../shared/util";
import {checkEnv} from "@blocksure/blocksure-core/dist/src/utilities";
import {useParams} from "react-router-dom";

global.notProd = (process.env.NODE_ENV !== 'production' ||
    (valueOrEnvVariable('!!env!!', 'prod') !== 'prod' &&
        valueOrEnvVariable('!!env!!', 'demo') !== 'demo' &&
        valueOrEnvVariable('!!env!!', 'uat') !== 'uat' &&
        valueOrEnvVariable('!!env!!', 'uatjp') !== 'uatjp' &&
        valueOrEnvVariable('!!env!!', 'uatjapan') !== 'uatjapan'));

const stripe = {
    prod: 'pk_live_7ROe1wUAvPh5KKFEDvVQcqfx',
    uat: 'pk_test_9G7xJCx8so7dzeFrtfp5TcJa',
    uatjp: 'pk_test_51HoSPJGQJBbGup87ADQGhyavsGfFkM1qg6Qd5ZCzfnXeuYGX0JO3TgtwgKXrL5MD3IIYm5tBGvKmkeZb3Ha8JZOu0085XsKln8',
    uatjapan: 'pk_test_51R7Fr2Ig51ff41fRV0I0EGxxIO17RDc7tqf0K1GOmMPMGzAu1fltNOwbR06RF6FPSAXphFX2DDNymlXz5SO1643E00viJTg9g6',
    ext: 'pk_test_VC2RDUh94ObACLaq6PlEsB89',
    demo: 'pk_test_wesf1w5bNpXU4nrlv8PU07fX00WE2Db4sj',
    sysm: 'pk_test_48w2wV66tvErT25b0qwG1dQs00arWRwgiw',
    sysr: 'pk_test_48w2wV66tvErT25b0qwG1dQs00arWRwgiw',
    nfr: 'pk_test_Fx1aXDJ0OWt43wJMlrRIFMeP00RwRfFj8Y',
    default: 'pk_test_AYKzX17LD4bYulSzZPaCQF64',
};

// do not use 'FormulatorAppName' there
// because it will set 'global' for using in SureLync MTA
const AppName = 'sureapp-user';
const localStorage = new NamespacedLocalStorage(global.localStorage, AppName);
const {env, startTime} = checkEnv();

if (+localStorage.getItem('localstorage-version') !== startTime) {
    localStorage.keys()
        .forEach(key => localStorage.removeItem(key));
    localStorage.removeItem('com.blocksure.addressLookupCacheV2');
    localStorage.setItem('app-name', AppName);
    localStorage.setItem('localstorage-version', startTime);
}

export const ProductRouter = ({surelyncProps}) => {
    const base = getBase();
    useLayoutEffect(() => {
        global.embedded = !!surelyncProps || window.location !== window.parent.location;

        if (global.embedded) {
            document.body.style.backgroundColor = 'transparent';
        }

    }, [surelyncProps]);

    useEffect(() => {
        // set 'luxon' locale
        i18n.on('languageChanged', function (locale) {
            FormatUtils.setGlobalLocale(locale);
        });
    }, []);

    const { id } = useParams();

    const getApiKey = () => {
        const currentEnv = surelyncProps ? surelyncProps.env.env : env;
        surelyncProps && console.info('Current env', env);
        return stripe[currentEnv.toLocaleLowerCase()] || stripe.default;
    }

    return (
        <div>
            <I18nextProvider i18n={i18n}>
                <LocalStorageContext.Provider value={{localStorage: new NamespacedLocalStorage(global.localStorage, SureAppName)}}>
                    <StripeProvider apiKey={getApiKey()} locale={i18n.language}>
                        <Elements>
                            <TradingGroupProvider tradingId={id || base} surelyncProps={surelyncProps}>
                                <PendingProvider>
                                    <AuthenticationProvider surelyncProps={surelyncProps}>
                                        <AuthenticatedRoutes/>
                                    </AuthenticationProvider>
                                </PendingProvider>
                                {surelyncProps
                                    ? null
                                    : global.embedded ? <PoweredBy/> : <Footer/>
                                }
                            </TradingGroupProvider>
                        </Elements>
                    </StripeProvider>
                </LocalStorageContext.Provider>
            </I18nextProvider>
        <div className="clearfix"/>
    </div>
    )
}
