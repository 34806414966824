import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import { hiddenRoles, IRole, isNotHiddenPermissions, TranslateUtils } from "@surelync/common";

export const setDataUserName = (data: any) => {
    const users = data.filter((user) => user.firstName && user.lastName && user.email !== "super");
    return orderBy(users, ["firstName", "lastName"]);
};

export const setDataRoleName = (data: IRole[]) => {
    const roles = data
        .filter((role) => {
            // SUR-134
            // We have a single user with username of removed on Jira. This user can see and assign the role 'Blocksure Support' / 'SureAPX Support'.
            // We have a role, Super, – users with this role should not be able to see or assign the "Blocksure Support" or "SureAPX Support" role.
            // So always hidden in the Select Role dropdown
            if (role.name === "Blocksure Support" || role.name === "SureAPX Support") {
                return false;
            } else {
                return hiddenRoles.indexOf(role.category) === -1;
            }
        })
        .map((role) => {
            const permissions = role.permissions.filter(isNotHiddenPermissions);
            const translate = TranslateUtils.translateRole(role.name);
            return {
                id: role.id,
                name: translate.name,
                description: translate.description || role.description,
                permissions: uniqBy(permissions, (element) => element.id),
            };
        });

    return orderBy(roles, "name");
};

export const setDataUser = (roles: any, choosenRoleIds: string[]) => {
    const result = [];

    roles
        .filter((role) => choosenRoleIds.includes(role.id))
        .forEach((role) => {
            const permissions = role.permissions.filter(isNotHiddenPermissions);
            const translate = TranslateUtils.translateRole(role.name);
            result.push({
                name: translate.name,
                id: role.id,
                description: translate.description || role.description,
                permissions: uniqBy(permissions, (element) => element.id),
                removeButton: true,
            });
        });

    return result;
};
